import { Link } from "@ludens-reklame/rubics-v3-sdk/link/types";
import { getLinkProps } from "../../client-utils/getLinkProps.js";
import { arrow } from "../../icons/svg.js";

interface Card {
  title?: string;
  link?: Link;
}

interface Props {
  cards?: Card[];
}

const CtaMagazine: React.FC<Props> = ({ cards = [] }) => {
  return (
    <div className="cta-magazine hs vs-xl mw">
      {cards.map((card, index) => (
        <div key={index} className="container">
          {card.title && <h2 className="h6">{card.title}</h2>}
          {card.link && (
            <a
              className={`button ${index % 2 === 0 ? "tertiary" : ""}`}
              {...getLinkProps(card.link)}
            >
              {card.link.title}
              {arrow}
            </a>
          )}
        </div>
      ))}
    </div>
  );
};

export default CtaMagazine;
